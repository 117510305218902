const config = {
    // sourceUrl: "http://localhost:80",
    sourceUrl: "https://synagouge-server.herokuapp.com",
    language: localStorage.getItem('APP_LANGUAGE') && localStorage.getItem('APP_LANGUAGE') === 'en' ? null : "hebrew", //hebrew or english
    appVersion: "3.0.349",
    lastUpdate: '20.4.24',
    develop: false, //for develop set it true
    cityLocations: [
        {
            pos: {latitude: 31.898472, longitude: 34.842722},
            name: "רחובות",
            // showFirst: true //when this flag is true then its the default value in TimesOfTheDay
        },
        {
            pos: {latitude: 31.7712682, longitude: 35.1984444},
            name: "ירושלים"
        },
        {
            pos: {latitude: 31.5286455, longitude: 34.5854942},
            name: "שדרות"
        },
        {
            pos: {latitude: 33.180829, longitude: 35.7504893},
            name: "רמת הגולן"
        },
        {
            pos: {latitude: 32.413635, longitude: 35.2516373},
            name: "השומרון"
        },
        {
            pos: {latitude: 32.0919291, longitude: 34.9418544},
            name: "ראש העין"
        },
        {
            pos: {latitude: 31.652419, longitude: 35.1199013},
            name: "גוש עציון"
        },
        {
            pos: {latitude: 31.531983, longitude: 35.0974653},
            name: "חברון"
        },
        {
            pos: {latitude: 31.24984, longitude: 34.7861193},
            name: "באר שבע"
        },
        {
            pos: {latitude: 32.065237, longitude: 34.7758273},
            name: "תל אביב"
        },
        {
            pos: {latitude: 29.554871, longitude: 34.9432523},
            name: "אילת"
        }
    ],
    days : {
        0: "ראשון",
        1: "שני",
        2: "שלישי",
        3: "רביעי",
        4: "חמישי",
        5: "שישי",
        6: "שבת"
    },
    tfilaTypes: {
        shaharit: "שחרית",
        minha : "מנחה",
        arvit: "ערבית",
        kabalatShabat: "קבלת שבת",
        customize: "תפילה מותאמת אישית"
    },
    minUpdateUserAction: 60 * 5, //in seconds
    hebrewNumToDay:{
        1: "א",
        2: "ב",
        3: "ג",
        4: "ד",
        5: "ה",
        6: "ו",
        7: "ז",
        8: "ח",
        9: "ט",
        10: "י",
        11: "י״א",
        12: "י״ב",
        13: "י״ג",
        14: "י״ד",
        15: "ט״ו",
        16: "ט״ז",
        17: "י״ז",
        18: "י״ח",
        19: "י״ט",
        20: "כ",
        21: "כ״א",
        22: "כ״ב",
        23: "כ״ג",
        24: "כ״ד",
        25: "כ״ה",
        26: "כ״ו",
        27: "כ״ז",
        28: "כ״ח",
        29: "כ״ט",
        30: "ל",
        31: "ל״א"
    },
    hebrewDayToNum: {"א":1,"ב":2,"ג":3,"ד":4,"ה":5,"ו":6,"ז":7,"ח":8,"ט":9,"י":10,"י״א":11,"י״ב":12,"י״ג":13,"י״ד":14,"ט״ו":15,"ט״ז":16,"י״ז":17,"י״ח":18,"י״ט":19,"כ":20,"כ״א":21,"כ״ב":22,"כ״ג":23,"כ״ד":24,"כ״ה":25,"כ״ו":26,"כ״ז":27,"כ״ח":28,"כ״ט":29,"ל":30,"ל״א":31},
    hebrewNumToMonth:{
        1: "תשרי",
        2: "חשון",
        3: "כסליו",
        4: "טבת",
        5: "שבט",
        14: "אדר",
        6: "אדר א",
        7: "אדר ב",
        8: "ניסן",
        9: "אייר",
        10: "סיון",
        11: "תמוז",
        12: "אב",
        13: "אלול",
    },
    hebrewMonths: ['תשרי', 'חשון', 'כסליו', 'טבת', 'שבט','אדר', 'אדר א', 'אדר ב', 'ניסן', 'אייר', 'סיון', 'תמוז', 'אב', 'אלול'],
    englishMonthToHebrew: {
        Tishrei: 'תשרי',
        Cheshvan: 'חשון',
        Kislev: 'כסליו',
        Tevet: 'טבת',
        "Sh'vat": 'שבט',
        Adar: 'אדר',
        "Adar 1": 'אדר א',
        "Adar 2": 'אדר ב',
        Nisan: 'ניסן',
        Iyyar: 'אייר',
        Sivan: 'סיון',
        Tamuz: 'תמוז',
        Av: 'אב',
        Elul: 'אלול',
    },
    hebrewMonthToNum: {"תשרי":1,"חשון":2,"כסליו":3,"טבת":4,"שבט":5,"אדר":6,"אדר א":6,"אדר ב":7,"ניסן":8,"אייר":9,"סיון":10,"תמוז":11,"אב":12,"אלול":13},
    hebrewMonthEnfToHebrew: {"Tishrei": 'תשרי', "Cheshvan": "חשון", "Kislev": "כסליו", "Tevet": "טבת", "Sh'vat": "שבט", "Adar": "אדר", "Nisan": "ניסן", "Iyyar": "אייר", "Sivan": "סיוון", "Tamuz": "תמוז", "Av": "אב", "Elul": "אלול", "Adar 1": "אדר א", "Adar 2": "אדר ב"},
    dashboardAutoRefreshIntervalMs: 1000 * 60 * 60,
    uniSynGoogleSheetsUrl: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRWRuTu1TTEsIEZ1e62_WJh3C5yhc18wFUMaPx2S_geaH7k760xyyW1Fs8PTxaoF8rma81AFPZmM9Xq/pubhtml?gid=0&single=true',
    yeshivaTimes: {
        tel_aviv: {eng: 'tel_aviv', 'heb': 'תל אביב'},
        pduel: {eng: 'pduel', 'heb': 'פדואל'},
        jerusalem: {eng: 'jerusalem', 'heb': 'ירושלים'},
        london: {eng: 'london', 'heb': 'לונדון'},
        holon: {eng: 'holon', 'heb': 'חולון'},
        haifa: {eng: 'haifa', 'heb': 'חיפה'},
        tveria: {eng: 'tveria', 'heb': 'טבריה'},
        beitarElit: {eng: 'beitarElit', 'heb': 'ביתר עלית'},
        beitShaan: {eng: 'beitShaan', 'heb': 'בית שאן'},
        nofHagalil: {eng: 'nofHagalil', 'heb': 'נוף הגליל'},
        yavne: {eng: 'yavne', 'heb': 'יבנה'},
        kiryatNetafim: {eng: 'kiryatNetafim', 'heb': 'קרית נטפים'},
        beit_ezra_kfar_sava: {eng: 'beit_ezra_kfar_sava', 'heb': 'בית עזרא כפר סבא'},
        shechem: {eng: 'shechem', 'heb': 'שכם'},
        shaveishom: {eng: 'shaveishom', 'heb': 'שבי שומרון'}
    },
    defaultShabatEnterMinBeforeSunset: 21,
    defaultShabatExitMinAfterStarsCameOut: 0,
    androidLink: 'https://play.google.com/store/apps/details?id=net.mysynagogue.twa',
    feedbackPhone: '+972549445909', //or cohen
    importantDates: [
        {
            hebrewMonth: 'Elul',
            hebrewDayAtMonth: 29,
            name: 'ערב ראש השנה'
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 1,
            name: 'ראש השנה - א',
            showHolidayStartAndEnd: true
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 2,
            name: 'ראש השנה - ב',
            showHolidayStartAndEnd: false,
            // holidayStart: 'yesterday_holiday_end',
            // holidayEnd: 'if_day_is_5_do_shabat_enter'
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 3,
            name: 'צום גדליה',
            showHolidayStartAndEnd: true,
            fast: true
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 9,
            name: 'ערב יום כיפור',
            showHolidayStartAndEnd: false,
            fast: false
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 10,
            name: 'יום כיפור',
            showHolidayStartAndEnd: true,
            fast: true
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 14,
            name: 'ערב סוכות',
            showHolidayStartAndEnd: false,
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 15,
            name: 'סוכות',
            showHolidayStartAndEnd: true,
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: [16,17,18,19],
            name: 'חול המועד סוכות'
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 21,
            name: 'הושענא רבא',
            showHolidayStartAndEnd: false,
        },
        {
            hebrewMonth: 'Tishrei',
            hebrewDayAtMonth: 22,
            name: 'שמיני עצרת',
            showHolidayStartAndEnd: true,
        },
        {
            hebrewMonth: 'Adar 2',
            hebrewDayAtMonth: 11,
            name: 'תענית אסתר',
            showHolidayStartAndEnd: true,
            fast: true
        },
        {
            hebrewMonth: 'Adar 2',
            hebrewDayAtMonth: 13,
            name: 'ערב פורים'
        },
        {
            hebrewMonth: 'Adar 2',
            hebrewDayAtMonth: 14,
            name: 'פורים'
        },
        {
            hebrewMonth: 'Adar 2',
            hebrewDayAtMonth: 15,
            name: 'שושן פורים'
        },
        {
            hebrewMonth: 'Nisan',
            hebrewDayAtMonth: 14,
            name: 'ערב פסח',
            showHolidayStartAndEnd: true
        },
        {
            hebrewMonth: 'Nisan',
            hebrewDayAtMonth: 15,
            name: 'פסח'
        },
        {
            hebrewMonth: 'Nisan',
            hebrewDayAtMonth: [16,17,18,19],
            name: 'חול המועד פסח'
        },
        {
            hebrewMonth: 'Nisan',
            hebrewDayAtMonth: 20,
            name: 'ערב שביעי של פסח',
            showHolidayStartAndEnd: true
        },
        {
            hebrewMonth: 'Nisan',
            hebrewDayAtMonth: 21,
            name: 'שביעי של פסח'
        },
        {
            hebrewMonth: 'Nisan',
            hebrewDayAtMonth: 27,
            name: 'יום הזכרון לשואה ולגבורה'
        },
        {
            hebrewMonth: 'Iyyar',
            hebrewDayAtMonth: 5,
            name: 'יום הזכרון לחללי מערכות ישראל'
        },
        {
            hebrewMonth: 'Iyyar',
            hebrewDayAtMonth: 6,
            name: 'יום העצמאות'
        },
        {
            hebrewMonth: 'Iyyar',
            hebrewDayAtMonth: 17,
            name: 'ערב ל"ג בעומר'
        },
        {
            hebrewMonth: 'Iyyar',
            hebrewDayAtMonth: 18,
            name: 'ל"ג בעומר'
        },
        {
            hebrewMonth: 'Iyyar',
            hebrewDayAtMonth: 27,
            name: 'ערב יום שחרור ירושלים'
        },
        {
            hebrewMonth: 'Iyyar',
            hebrewDayAtMonth: 28,
            name: 'יום שחרור ירושלים'
        },
        {
            hebrewMonth: 'Sivan',
            hebrewDayAtMonth: 5,
            name: 'ערב שבועות'
        },
        {
            hebrewMonth: 'Sivan',
            hebrewDayAtMonth: 6,
            name: 'שבועות',
            showHolidayStartAndEnd: true
        },
        {
            hebrewMonth: 'Tamuz',
            hebrewDayAtMonth: 17,
            name: 'צום י״ז בתמוז',
            showHolidayStartAndEnd: true,
            fast: true
        },
        {
            hebrewMonth: 'Av',
            hebrewDayAtMonth: 8,
            name: 'ערב תשעה באב',
            showHolidayStartAndEnd: false,
            fast: true
        },
        {
            hebrewMonth: 'Av',
            hebrewDayAtMonth: 9,
            name: 'תשעה באב',
            showHolidayStartAndEnd: true,
            fast: true
        }

    ]
};

export default config;