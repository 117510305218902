import SingletoneStoreClass from "../../../../../Store/Store";
import TextViewer from "../../../../../helpers/textViewer";
import tfilotParser from "../../../../../helpers/TfilotOfTheDay/tfilotParser";
import utils from "../../../../../helpers/utils";
import localQrs from './localBase64Qr';
import nezerAharonMap from './nezerAharonMap';
import MemoryBoard from './HebrewEventsBoard/memoryBoard';
import GeneralEventsViewer from './GeneralEventsViewer/generalEventsViewer';
import config from "../../../../../config";
import React from "react";
import ReactPlayer from "react-player/youtube";

const store = SingletoneStoreClass.getInstance();

const getPlasmaSlides = (props)=>{
    const { updates, location, currentDate, prayersHebrewDatesEvents } = props;
    let { qrCodeBase64 } = props;
    const weeklySpecialEvents = store.getWeeklySpecialEventsV2(new Date(), location, 5);
    const logicalName = store.getSynagogueName();

    const {monthName, dayOfMonth} = store.getHebrewDayAndMouthAndYear({date: new Date(), location});

    if(localQrs[logicalName]){
        console.info('using local QR!')
        qrCodeBase64 = localQrs[logicalName];
    }

let slides = [];

    utils.filterUpdates({updates}).forEach((update)=>{
        const {text = '', title = '', attachmentUrl, youtubeUrl, duration} = update;

        if(attachmentUrl && !text && !title){
            slides.push({
                slideId: 'updates',
                body: <div className={'plasma-slide-attachment'}>
                    {attachmentUrl.includes('pdf') ? <iframe src={attachmentUrl}/> : <img src={attachmentUrl} alt="avatar"/>}
                </div>,
                className: 'plasma-slide-full-size-picture',
                duration
            })
        }
        else if(youtubeUrl){
            slides.push({
                title: title ? <TextViewer text={title || 'gabaais_updates'}/> : null,
                slideId: 'updates',
                body: (props=>{

                    const  {isActive} = props;
                    return <div className={'plasma-slide-youtube'}>
                        <ReactPlayer
                            className={'player-container-plasma-slide'}
                            url={youtubeUrl}
                            playing={isActive}
                            // muted={true}
                            muted={false}
                            loop={true}
                        />
                    </div>
                }),
                video: true,
                className: 'plasma-slide-full-size-picture',
                duration
            })
        }
        else{
            slides.push({
                slideId: 'updates',
                title: <TextViewer text={title || 'gabaais_updates'}/>,
                subTitle: attachmentUrl ? <TextViewer text={text}/> : null,
                body: attachmentUrl ? <div className={'plasma-slide-attachment'}>
                    {attachmentUrl.includes('pdf') ? <iframe src={attachmentUrl}/> : <img src={attachmentUrl} alt="avatar"/>}
                </div> : text,
                type: 'text',
                duration
            })
        }


    })


    if(weeklySpecialEvents && weeklySpecialEvents.length >0){
        slides.push({
            slideId: 'weeklySpecialEvents',
            title: <TextViewer text={'important_dates'}/>,
            body: <div className={'important-dates-slide'}>{weeklySpecialEvents.map(event=><div>
                {event.hebrewDate} - {event.desc}
            </div>)}</div>,
            type: 'component'
        })
    }

    const timesOfDay = tfilotParser.calcTimesOfDayV2({
        date: currentDate,
        location
    });

    slides.push({
        slideId: 'timesOfDay',
        title: <TextViewer text={'times_of_the_day'}/>,
        body: <div className={'time-of-the-day-slide'}>
            {utils.sortTimes(timesOfDay).map(event=><div>
                <TextViewer text={event.name}/> - {utils.dateToHH_MM(event.date)}
            </div>)}
        </div>,
        type: 'component'
    })

    slides.push({
        slideId: 'scanBarcode',
        title: <TextViewer text={'connect_from_mobile'}/>,
        body: <div className={'scan-barcode-slide'}>
            <img src={qrCodeBase64}/>
            <div>
                <a>{`${window.location.origin}/${window.location.pathname.split("/")[1]}`}</a>
            </div>
        </div>,
        type: 'component'
    })

    let isMashivHaRuach = false;

    if(["Cheshvan", "Kislev", "Tevet", "Sh'vat", "Adar", "Adar 1", "Adar 2"].includes(monthName)){
        isMashivHaRuach = true;
    }else if(monthName === 'Tishrei' && dayOfMonth >= 22){
        isMashivHaRuach = true;
    }else if(monthName === 'Nisan' && dayOfMonth <= 14){
        isMashivHaRuach = true;
    }

    let isTenTalUmatar = false;
    if([ "Kislev", "Tevet", "Sh'vat", "Adar", "Adar 1", "Adar 2"].includes(monthName)){
        isTenTalUmatar = true;
    }else if(monthName === 'Cheshvan' && dayOfMonth >= 7){
        isTenTalUmatar = true;
    }else if(monthName === 'Nisan' && dayOfMonth <= 14){
        isTenTalUmatar = true;
    }



    slides.push({
        slideId: 'mashivHaRuach',
        body: <div className={'mashiv-haRuach-slide'}>
            <div className={'main'}>
                {isMashivHaRuach ? 'מַשִּׁיב הָרוּחַ וּמוֹרִיד הַגֶּשֶׁם' : 'מוֹרִיד הַטַּל'}
            </div>
            <div className={'sub-main'}>
                {isTenTalUmatar ? 'וְתֵן טַל וּמָטָר לִבְרָכָה עַל פְּנֵי הָאֲדָמָה / בָרֵךְ עָלֵינוּ ה\' אֱלֹהֵינוּ אֶת הַשָּׁנָה הַזֹּאת' : 'וְתֵן בְּרָכָה / בַּרְכֵנוּ ה\' אֱלֹהֵינוּ בְּכָל־מַעֲשֵׂי יָדֵינוּ'}
            </div>
        </div>,
        type: 'component'
    })

    if(nezerAharonMap[`${dayOfMonth}-${monthName}`]){
        nezerAharonMap[`${dayOfMonth}-${monthName}`].forEach(imgUrl=>{
            slides.push({
                slideId: 'nezerAharon',
                title: <TextViewer text={'אנא אמרו קדיש עבור חללי נצר אחרון'}/>,
                body: <img src={imgUrl} alt="avatar"/>,
                type: 'component'
            })
        })

    }


    if(prayersHebrewDatesEvents && prayersHebrewDatesEvents.length){

        const getLastSunday = ()=>{
            const currentDate = new Date();
            const currentDay = currentDate.getDay();
            const offsetToLastSunday = (currentDay + 7) % 7;
            const lastSundayDate = new Date(currentDate);
            lastSundayDate.setDate(currentDate.getDate() - offsetToLastSunday);
            return lastSundayDate;
        }

        const memorialPrayersHebrewDatesEvents = [];
        const birthdayPrayersHebrewDatesEvents = [];
        const lastSunday = getLastSunday();
        const hebrewDatesNextWeekMap = {};
        for(let i=0; i<=7; i++){
            const { monthName, dayOfMonth } = store.getHebrewDayAndMouthAndYear({date: lastSunday, location});
            if(monthName === 'Kislev'){
                hebrewDatesNextWeekMap[`כסליו_${config.hebrewNumToDay[dayOfMonth]}`] = true;
                hebrewDatesNextWeekMap[`כסלו_${config.hebrewNumToDay[dayOfMonth]}`] = true;

            }else{
                hebrewDatesNextWeekMap[`${config.englishMonthToHebrew[monthName]}_${config.hebrewNumToDay[dayOfMonth]}`] = true;
            }
            lastSunday.setDate(lastSunday.getDate()+1);
        }

        prayersHebrewDatesEvents.forEach(event=>{
            if(['יארצייט','יורצייט'].includes(event.reason)){
                if(hebrewDatesNextWeekMap[`${event.hebrewMonth}_${event.hebrewDay}`]){
                    memorialPrayersHebrewDatesEvents.push(event)
                }
            }
            if(['יום הולדת'].includes(event.reason)){
                if(hebrewDatesNextWeekMap[`${event.hebrewMonth}_${event.hebrewDay}`]){
                    birthdayPrayersHebrewDatesEvents.push(event)
                }
            }
        })

        if(memorialPrayersHebrewDatesEvents.length > 0) slides.push({
            slideId: 'memoryBoard',
            title: <TextViewer text={'לזכרון עולם בהיכל ה\''}/>,
            body: <MemoryBoard location={location} prayersHebrewDatesEvents={memorialPrayersHebrewDatesEvents} types={['יארצייט','יורצייט']}/>,
            type: 'component'
        })


        if(birthdayPrayersHebrewDatesEvents.length > 0){

            if(birthdayPrayersHebrewDatesEvents.length <= 6){
                slides.push({
                    slideId: 'birthdayBoard',
                    title: <TextViewer text={'🎈    ימי הולדת בקהילה    🎉'}/>,
                    body: <MemoryBoard location={location} prayersHebrewDatesEvents={birthdayPrayersHebrewDatesEvents} types={['יום הולדת']}/>,
                    type: 'component'
                })
            }
            else{
                let chunks = [],
                    i = 0,
                    n = birthdayPrayersHebrewDatesEvents.length;

                while (i < n) {
                    chunks.push(birthdayPrayersHebrewDatesEvents.slice(i, i += 6));
                }
                chunks.forEach(chunk=>{
                    slides.push({
                        slideId: 'birthdayBoard',
                        title: <TextViewer text={'🎈    ימי הולדת בקהילה    🎉'}/>,
                        body: <MemoryBoard location={location} prayersHebrewDatesEvents={chunk} types={['יום הולדת']}/>,
                        type: 'component'
                    })
                })
            }


        }
    }

    const events = store.getEventsPerDate(currentDate, location);
    if(events.length){
        slides.push({
            slideId: 'generalEventsViewer',
            title: null,
            body: <GeneralEventsViewer events={events}/>,
            type: 'component'
        })
    }


    if(!window.navigator.onLine){
        slides = slides.filter(slide=>!slide.video)
    }


    return slides;
}

export default getPlasmaSlides;